.appbody {
    width: 100%;
    min-height: 100vh;
    /* height: 100%;  */
    /* background-image: url('./../../../assets/img/images/bookback.svg');
    background-size: cover;
    background-position: center; */
    /* background-color: pink; */
}

.logo {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    /* padding:32px; */
}
.cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.firstColumn {
    padding: 40px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.firstColumnheader {
    text-align: center;
    font-size: 32px;
    line-height: 26px;
    color: #4e4d4e;
    font-weight: 700;
}
.firstColumnheader2 {
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    color: black;
    font-weight: 600;
    margin-top: 20px;
}
.secondColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(224, 238, 249, 0.5);
    padding: 32px 20px;
    height: 100%;
}
.timebox {
    background-color: white;
    border: solid 1px #e1e1e1;
    border-radius: 5px;
    /* margin: 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 5px 15px; */
    cursor: pointer;
    height: 90px;
    flex: 1;
    width: 100%;
    /* overflow: scroll; */
}
.selectedtimebox {
    /* flex:1; */
    background-color: #ddecf7;
    border: solid 1px #5ca5e0;
    border-radius: 5px;
    /* margin: 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 5px 15px; */
    cursor: pointer;
    height: 90px;
}
.timebox:hover {
    background-color: #ddecf7;
    border: solid 1px #5ca5e0;
}
.timebox:hover .timeboxicon {
    color: #5ca5e0;
}
.timeboxiconselected {
    color: #5ca5e0;
}
.timeboxmodal {
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    background-color: white;
    border: solid 1px #e1e1e1;
    cursor: pointer;
}
.selectedtimeboxmodal {
    /* flex:1; */
    background-color: #ddecf7;
    border: solid 3px #5ca5e0;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.timeboxmodal:hover {
    background-color: #ddecf7;
    border: solid 1px #5ca5e0;
}

.submitButtoncont {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.submitButton {
    border: none;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    background-color: #0173ce;
    border-radius: 5px;
}
.points {
    display: flex;
    align-items: center;
    margin: 5px;
}
.thankstext {
    font-size: 1rem;
    font-weight: 700;
    color: black;
    margin-top: 20px;
    margin-bottom: 15px;
}
@media (max-width: 767px) {
    .logo {
        color: white;
        font-size: 1.3rem;
        line-height: 1.4; 
        font-weight: 700;
        /* padding:32px; */
    }
    .firstColumnheader {
        font-size: 26px;
    }
}
