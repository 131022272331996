@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap);
/* Common Styling */
.Homepage_appbody__1hQqD {
    background-color: white;
}
.Homepage_colContainer__15KTh {
    border: 15px;
    padding: 30px;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}
.Homepage_bottomlink__1Lbr0 {
}
.Homepage_containerInside__305zg {
    width: 1140px;
    height: 100%;
}
.Homepage_formaterBlock__yQzss {
    display: none;
}
/* Styling for header */
.Homepage_header__3VZZN {
    /* height: 4rem; */
    background-color: #fafafa;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Homepage_headerInside__18-Ci {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Homepage_headerRight__1SLc0 {
    display: flex;
    align-items: center;
}
.Homepage_headertext__3Cx-e {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
}
.Homepage_headerLeft__1r1hd {
    display: flex;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
}
.Homepage_headerLeftTabs__3lES9 {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.Homepage_headerLeftTabsdata__105cz {
    /* text-align: center; */
    line-height: 0.7;
    /* margin-top: 5px; */
}
.Homepage_extramargin__3VbdD {
    margin-left: 250px !important;
    padding: 5px;
}
.Homepage_headerLeftTabsheader__gnIlU {
    /* text-align: center; */
    font-size: 0.8rem;
    color: black;
    font-weight: bold;
}

/* Styling for Container 1 */
.Homepage_container1__2HQQt {
    /* background-image: url('../../../assets/img/images/5.jpg');
    background-size: cover;
    background-position: center; */

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* background-color: white; */
}
.Homepage_container1supp__3bs_o {
    /* display: flex; */
    /* justify-content: center; */
    /* min-height: "100%"; */
    /* align-items: center; */
    /* background-color: red; */
    /* overflow:hidden; */
    /* width: 100%; */
    /* background-color: white; */
}
@media (min-width: 768px) {
    .Homepage_container1supp__3bs_o {
        display: flex;
        min-height: 650px;
    }
}
.Homepage_imgContainer__33nsb {
    border: 15px;
    /* padding: 30px; */
    padding-top: 30px;
    /* padding-right: 30px; */
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}
/* .homepageHeroImage{

    height: 470px;
    width: 470px;

    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;

    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
} */
.Homepage_homepageHeroImage__2kYaM {
    max-height: 500px;
    max-width: 500px;
    overflow: hidden;
    /* object-fit: cover; */
    /* border: 9px solid #292d33; */
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    /* position: absolute; */
    border-radius: 5px;
    /* background-color: white;
}
.homepageHeroImagecont{
    height: 450px;
    width: 450px;
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    position: absolute;
    border-radius: 5px;
    display: flex;
    justify-content: end; */
}
.Homepage_homepageHeroImageBox1__1S1sW {
    height: 500px;
    width: 250px;
    background-color: rgb(0, 123, 255, 0.85);
    border-radius: 5px;
    position: relative;
    margin-left: 240px;
}
.Homepage_homepageHeroImageBox2__M9M4s {
    height: 180px;
    width: 500px;
    background-color: rgb(224, 187, 228, 0.9);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: 5px;
    position: absolute;
    /* top: 200px; */
    margin-top: 250px;
}

.Homepage_homepageHerofont__US1BT {
    font-size: 3.75rem;
    font-weight: 700;
    color: black;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.1;
}

.Homepage_homepageHeropara__TSijo {
    font-size: 1.25rem;
    margin: 29px 0 0;
    color: black;
    word-spacing: 0.2;
    /* background-color: pink; */
    /* border-bottom: 4px solid black; */
    line-height: 1.6;
    font-family: 'Open Sans', sans-serif;
}
.Homepage_homepageHerobtncontainer__3Dhlg {
    align-self: flex-start;
    margin: 22px 0 0;
}
.Homepage_homepageHerobtn__2BPGt {
    width: 160px;
    margin-right: 10px;
    margin-top: 10px;

    padding: 10px 20px;
    font-size: 0.9375rem;
    font-weight: 500;
    background-color: white;
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.Homepage_homepageHerobtnmail__1H3sj {
    margin-right: 10px;
    margin-top: 10px;
    width: 160px;
    padding: 10px 20px;
    font-size: 0.9375rem;
    font-weight: 500;
    background-color: white;
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.Homepage_homepageHerobtnpay__2c-RU {
    display: none;
}
.Homepage_homepageHerobtn__2BPGt:hover {
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.Homepage_homepageHerobtnpay__2c-RU:hover {
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.Homepage_homepageHerobtn2__2w07L {
    width: 160px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid black;
    padding: 10px 40px;
    font-size: 0.9375rem;
    font-weight: 500;
}
.Homepage_homepageHerobtn2__2w07L:hover {
    background-color: white;
    border: 1px solid black;
}

/* Styling for Container 2 */
.Homepage_container2__DXVP- {
    background-color: #292d33;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 40px;
}
.Homepage_container2Inside__2ZPSK {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.Homepage_container2Header__1XCuY {
    color: white;
    font-size: 3.43rem;
    margin-top: 60px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    /* width: 753px; */
}
.Homepage_container2Desc__3CioS {
    color: white;
    font-size: 1.125rem;
    margin-top: 20px;
    text-align: center;
    width: 659px;
}
.Homepage_container2Cardscontainer__3b_V2 {
    /* display: flex; */
    /* justify-content: space-around; */
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
}
.Homepage_container2Card__3gd0u {
    background-color: white;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}
.Homepage_containerteamCard__EI6tS {
    /* background-color: white; */
    /* border-radius: 20px; */
    /* background: rgba(0,0,0,0.5); */
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

/* Styling For Container 3 */
.Homepage_container3__1DZPb {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url(/static/media/t4.665e890b.png);
    background-size: cover;
    background-position: center;
    background-color: #f2f2f2;
}
.Homepage_container3Inside__1-3KJ {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.Homepage_container3Header__XTtOo {
    color: black;
    font-size: 3rem;
    margin-top: 85px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    width: 753px;
}
.Homepage_container3Cardscontainer__1MpAc {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 60px;
}
.Homepage_container3Card__UV5iO {
    background-color: white;
    border-radius: 20px;
    /* height: 40px; */
    margin: 10px;
    padding: 30px;
    display: flex;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
}

/* Styling For Container 4 */
.Homepage_container4__g9OHI {
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffca01;
}
.Homepage_container4Header__hJGm2 {
    color: black;
    font-size: 2.5rem;
    margin-top: 20x;
    /* text-align: center; */
    line-height: 1.1;
    font-weight: 700;
    /* width: 753px; */
}
.Homepage_container4mod__2S1ws {
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffca01;
}
.Homepage_container4modInside__2FK8W {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.Homepage_container4Cardscontainer__3vd66 {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 80px;
}

.Homepage_containersend__3HhVX {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    /* overflow:hidden;
   background-image: url('../../../assets/img/images/t4.png');
   background-size: cover;
   background-position: center; */
}
/* Styling For Container 5 */
.Homepage_container5__1sGdj {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
}

.Homepage_container5Inside__18rBL {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.Homepage_container5Header__mNAuO {
    color: black;
    font-size: 3.43rem;
    margin-top: 60px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    width: 753px;
}
.Homepage_containersendHeader__1mAtC {
    color: black;
    font-size: 2rem;
    margin-top: 40px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    /* width: 753px; */
}

.Homepage_containersendCardscontainer__2rjbz {
    /* width: 5000; */
    margin-top: 30px;
    margin-bottom: 30px;
}
.Homepage_container5Cardscontainer__2g02R {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.Homepage_container5Image__OqvYc {
    width: 100%;
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
}
.Homepage_contactUsBox__FqLrX {
    width: 900px;
}
/* New Styling addition */
.Homepage_container14__3Mc58 {
    background-color: #292d33;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 80px;
    padding-top: 80px;
}

.Homepage_container15__2537s {
    background-color: #212121;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 25px;
}
.Homepage_bottomBand__F5SYN {
    display: flex;
    justify-content: space-between;
    color: white;
    text-align: center;
    align-items: center;
}
.Homepage_secondBottomBand__1o9gl {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}
.Homepage_secondBottomtext__vGVNa {
    font-size: 2.3rem;
    color: white;
    text-align: center;
}
.Homepage_secondBottomaddhead__3KF8r {
    font-size: 1.4rem;
    color: #d9d4d4;
}
.Homepage_secondBottomadd__3dGHX {
    font-size: 1rem;
    color: #a6a6a6;
    white-space: pre-line;
    margin-top: 20px;
}
.Homepage_imageGallary__2Npm6 {
    width: 161px;
    height: 161px;
    -o-object-fit: cover;
       object-fit: cover;
    margin: 10px;
    cursor: pointer;
}
.Homepage_container8__2_HWq {
    background-color: #292d33;
    /* padding-bottom: 40px; */
}

.Homepage_submitButton__23qQH {
    border: none;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    background-color: #0173ce;
    border-radius: 5px;
}
.Homepage_aboutUsimage__2BPiu {
    /* height: "250px",
    width: "250px", */
    height: 280px;
    width: 280px;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .Homepage_containerInside__305zg {
        width: 940px;
        height: 100%;
    }

    .Homepage_homepageHerofont__US1BT {
        font-size: 3.125rem;
        font-weight: 700;
        color: black;
    }
    /* .homepageHeroImage{

        max-height: 590px;
        width: auto;


    } */
    .Homepage_contactUsBox__FqLrX {
        width: 900px;
    }
    .Homepage_homepageHeroImage__2kYaM {
        max-height: 500px;
        max-width: 500px;
        background-color: white;
    }

    .Homepage_homepageHeroImageBox1__1S1sW {
        height: 450px;
        width: 200px;
        background-color: rgb(0, 123, 255, 0.85);
        border-radius: 5px;
        position: relative;
        margin-left: 290px;
    }

    .Homepage_homepageHeroImageBox2__M9M4s {
        height: 140px;
        width: 430px;
        background-color: rgb(224, 187, 228, 0.9);
        -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
        border-radius: 5px;
        position: absolute;
        /* top: 200px; */
        margin-top: 250px;
    }
    .Homepage_container5Image__OqvYc {
        width: 100%;
        height: 321px;
        -o-object-fit: cover;
           object-fit: cover;
    }
    .Homepage_imageGallary__2Npm6 {
        width: 161px;
        height: 161px;
        -o-object-fit: cover;
           object-fit: cover;
        margin: 10px;
    }
}
@media (min-width: 768px) and (max-width: 990px) {
    .Homepage_interestedtext__2m0k8 {
        font-size: 40px !important;
    }
    .Homepage_getquotebtn__2cq-Y {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        padding-right: 18px !important;
        padding-left: 18px !important;
        font-size: 18px !important;
    }
    .Homepage_headerLeft__1r1hd {
        display: none;
    }
    .Homepage_homepageHerobtnpay__2c-RU {
        display: block;
        margin-right: 10px;
        margin-top: 10px;

        padding: 10px 20px;
        font-size: 0.9375rem;
        font-weight: 500;
        background-color: white;
        border: 1px solid black;
        /* border: 1px solid #007bff; */
    }
    .Homepage_containerInside__305zg {
        width: 720px;
        height: 100%;
    }
    .Homepage_contactUsBox__FqLrX {
        width: 100%;
    }
    /* .homepageHeroImage{

        max-height: 470px;
        width: auto;

    } */
    .Homepage_homepageHeroImage__2kYaM {
        max-height: 380px;
        max-width: 380px;
        background-color: white;
    }

    .Homepage_homepageHeroImageBox1__1S1sW {
        height: 400px;
        width: 150px;
        background-color: rgb(0, 123, 255, 0.85);
        border-radius: 5px;
        position: relative;
        margin-left: 290px;
    }

    .Homepage_homepageHeroImageBox2__M9M4s {
        height: 120px;
        width: 380px;
        background-color: rgb(224, 187, 228, 0.9);
        -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
        border-radius: 5px;
        position: absolute;
        /* top: 200px; */
        margin-top: 250px;
    }
    .Homepage_homepageHerofont__US1BT {
        font-size: 2.25rem;
        font-weight: 700;
        color: black;
    }
    .Homepage_homepageHeropara__TSijo {
        font-size: 1.125rem;
        margin: 29px 0 0;
        color: black;
    }
    .Homepage_homepageHerobtncontainer__3Dhlg {
        align-self: flex-start;
        margin: 19px 0 0;
    }
    .Homepage_imageGallary__2Npm6 {
        width: 161px;
        height: 161px;
        -o-object-fit: cover;
           object-fit: cover;
        margin: 10px;
    }
    .Homepage_extramargin__3VbdD {
        margin-left: 100px !important;
        padding: 5px;
    }
}
@media (min-width: 575px) and (max-width: 767px) {
    .Homepage_interestedtext__2m0k8 {
        font-size: 30px !important;
    }
    .Homepage_extramargin__3VbdD {
        margin-left: 0px !important;
        padding: 5px;
    }
    .Homepage_getquotebtn__2cq-Y {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-right: 17px !important;
        padding-left: 17px !important;
        font-size: 15px !important;
    }
    .Homepage_headerLeft__1r1hd {
        display: none;
    }
    .Homepage_contactUsBox__FqLrX {
        width: 100%;
    }
    .Homepage_homepageHerobtnpay__2c-RU {
        display: block;
        margin-right: 10px;
        margin-top: 10px;

        padding: 10px 20px;
        font-size: 0.9375rem;
        font-weight: 500;
        background-color: white;
        /* border: 1px solid #007bff; */
        border: 1px solid black;
    }
    .Homepage_containerInside__305zg {
        width: 540px;
        height: 100%;
    }
    .Homepage_container1__2HQQt {
        padding-top: 20px;
    }
    /* .homepageHeroImage{

        max-height: 470px;
        width: auto;

    } */
    .Homepage_homepageHeroImage__2kYaM {
        max-height: 380px;
        max-width: 3880px;
        background-color: white;
    }

    .Homepage_homepageHeroImageBox1__1S1sW {
        height: 400px;
        width: 150px;
        background-color: rgb(0, 123, 255, 0.85);
        border-radius: 5px;
        position: relative;
        margin-left: 290px;
        margin-bottom: 15px;
    }

    .Homepage_homepageHeroImageBox2__M9M4s {
        height: 120px;
        width: 380px;
        background-color: rgb(224, 187, 228, 0.9);
        -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
        border-radius: 5px;
        position: absolute;
        /* top: 200px; */
        margin-top: 250px;
    }
    .Homepage_homepageHerofont__US1BT {
        font-size: 2.25rem;
        font-weight: 700;
        color: black;
    }
    .Homepage_homepageHeropara__TSijo {
        font-size: 1.125rem;
        margin: 29px 0 0;
        color: black;
    }
    .Homepage_homepageHerobtncontainer__3Dhlg {
        align-self: flex-start;
        margin: 19px 0 0;
    }
    .Homepage_container2Header__1XCuY {
        width: 520px;
    }
    .Homepage_container2Desc__3CioS {
        width: 520px;
    }
    .Homepage_container3Header__XTtOo {
        width: 520px;
    }
    .Homepage_container5Header__mNAuO {
        width: 520px;
    }
    .Homepage_imageGallary__2Npm6 {
        width: 161px;
        height: 161px;
        -o-object-fit: cover;
           object-fit: cover;
        margin: 10px;
    }
    .Homepage_secondBottomBand__1o9gl {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .Homepage_secondBottomaddhead__3KF8r {
        font-size: 1.2rem;
        color: #d9d4d4;
        margin-top: 30px;
    }
}
@media (max-width: 574px) {
    .Homepage_bottomlink__1Lbr0 {
        margin-right: 0px !important;
        margin-top: 10px;
    }
    .Homepage_extratop__2K7m- {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .Homepage_interestedtext__2m0k8 {
        font-size: 30px !important;
    }
    .Homepage_extramargin__3VbdD {
        margin-left: 0px !important;
        padding: 5px;
    }
    .Homepage_getquotebtn__2cq-Y {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
        font-size: 15px !important;
    }
    .Homepage_headerLeft__1r1hd {
        display: none;
    }
    .Homepage_contactUsBox__FqLrX {
        width: 100%;
    }
    .Homepage_homepageHerobtnpay__2c-RU {
        display: block;
        margin-right: 10px;
        margin-top: 10px;

        padding: 10px 20px;
        font-size: 0.9375rem;
        font-weight: 500;
        background-color: white;
        border: 1px solid black;
        /* border: 1px solid #007bff; */
    }
    .Homepage_aboutUsimage__2BPiu {
        /* height: "250px",
        width: "250px", */
        height: 200px;
        width: 200px;
    }
    .Homepage_containerInside__305zg {
        max-width: 340px;
        width: 100%;
        height: 100%;
    }
    .Homepage_imgContainer__33nsb {
        padding: 5px 0px;
    }
    .Homepage_colContainer__15KTh {
        padding: 10px;
        padding-bottom: 50px !important;
        margin: 10px !important;
    }
    .Homepage_container1__2HQQt {
        padding-top: 20px;
    }
    /* .homepageHeroImage{

        max-height: 470px;
        width: auto;

    } */
    .Homepage_homepageHeroImage__2kYaM {
        max-height: 330px;
        max-width: 330px;
        background-color: white;
        position: static;
    }

    .Homepage_homepageHeroImageBox1__1S1sW {
        display: none;
    }

    .Homepage_homepageHeroImageBox2__M9M4s {
        display: none;
    }
    .Homepage_homepageHerofont__US1BT {
        font-size: 2.25rem;
        font-weight: 700;
        color: black;
    }
    .Homepage_homepageHeropara__TSijo {
        font-size: 1.125rem;
        margin: 29px 0 0;
        color: black;
    }
    .Homepage_homepageHerobtncontainer__3Dhlg {
        align-self: flex-start;
        margin: 19px 0 0;
    }
    .Homepage_container2Header__1XCuY {
        font-size: 1.82rem;
        max-width: 340px;
        width: 100%;
    }
    .Homepage_container2Desc__3CioS {
        max-width: 340px;
        width: 100%;
    }
    .Homepage_container3Header__XTtOo {
        font-size: 1.82rem;
        max-width: 340px;
        width: 100%;
    }

    .Homepage_container5Header__mNAuO {
        font-size: 1.82rem;
        max-width: 340px;
        width: 100%;
    }
    .Homepage_container4Header__hJGm2 {
        font-size: 1.82rem;
        width: 100%;
    }
    .Homepage_imageGallary__2Npm6 {
        max-width: 181px;
        width: 100%;
        height: 181px;
        -o-object-fit: cover;
           object-fit: cover;
        margin: 10px;
    }
    .Homepage_bottomBand__F5SYN {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
        text-align: center;
    }
    .Homepage_secondBottomBand__1o9gl {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .Homepage_secondBottomaddhead__3KF8r {
        font-size: 1.1rem;
        color: #d9d4d4;
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    /* .colContainer{
        text-align: center !important;
    } */
    .Homepage_homepageHerofont__US1BT {
        text-align: center !important;
    }
    .Homepage_homepageHeropara__TSijo {
        text-align: center !important;
    }
    .Homepage_homepageHerobtncontainer__3Dhlg {
        text-align: center !important;
        align-self: center !important;
    }
    .Homepage_homepageHerobtnpay__2c-RU {
        width: 97% !important;
    }
    .Homepage_homepageHerobtn2__2w07L {
        width: 97% !important;
    }
    .Homepage_homepageHerobtnmail__1H3sj {
        width: 97% !important;
    }
    .Homepage_homepageHerobtn__2BPGt {
        width: 97% !important;
    }
    .Homepage_formaterBlock__yQzss {
        display: block;
    }
}

.AddBooking_appbody__9iOhS {
    width: 100%;
    min-height: 100vh;
    /* height: 100%;  */
    /* background-image: url('./../../../assets/img/images/bookback.svg');
    background-size: cover;
    background-position: center; */
    /* background-color: pink; */
}

.AddBooking_logo__3umxJ {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    /* padding:32px; */
}
.AddBooking_cardContainer__31x8h {
    display: flex;
    justify-content: center;
    align-items: center;
}
.AddBooking_firstColumn__dI7Em {
    padding: 40px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.AddBooking_firstColumnheader__2ygdq {
    text-align: center;
    font-size: 32px;
    line-height: 26px;
    color: #4e4d4e;
    font-weight: 700;
}
.AddBooking_firstColumnheader2__2oOhi {
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    color: black;
    font-weight: 600;
    margin-top: 20px;
}
.AddBooking_secondColumn__3IB5Y {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(224, 238, 249, 0.5);
    padding: 32px 20px;
    height: 100%;
}
.AddBooking_timebox__2Kc0V {
    background-color: white;
    border: solid 1px #e1e1e1;
    border-radius: 5px;
    /* margin: 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 5px 15px; */
    cursor: pointer;
    height: 90px;
    flex: 1 1;
    width: 100%;
    /* overflow: scroll; */
}
.AddBooking_selectedtimebox__MReUO {
    /* flex:1; */
    background-color: #ddecf7;
    border: solid 1px #5ca5e0;
    border-radius: 5px;
    /* margin: 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 5px 15px; */
    cursor: pointer;
    height: 90px;
}
.AddBooking_timebox__2Kc0V:hover {
    background-color: #ddecf7;
    border: solid 1px #5ca5e0;
}
.AddBooking_timebox__2Kc0V:hover .AddBooking_timeboxicon__xAGVS {
    color: #5ca5e0;
}
.AddBooking_timeboxiconselected__2SKZn {
    color: #5ca5e0;
}
.AddBooking_timeboxmodal__3vbSN {
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    background-color: white;
    border: solid 1px #e1e1e1;
    cursor: pointer;
}
.AddBooking_selectedtimeboxmodal__1_avx {
    /* flex:1; */
    background-color: #ddecf7;
    border: solid 3px #5ca5e0;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.AddBooking_timeboxmodal__3vbSN:hover {
    background-color: #ddecf7;
    border: solid 1px #5ca5e0;
}

.AddBooking_submitButtoncont__348Us {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.AddBooking_submitButton__1fqF_ {
    border: none;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    background-color: #0173ce;
    border-radius: 5px;
}
.AddBooking_points__16DTb {
    display: flex;
    align-items: center;
    margin: 5px;
}
.AddBooking_thankstext__3b6Gy {
    font-size: 1rem;
    font-weight: 700;
    color: black;
    margin-top: 20px;
    margin-bottom: 15px;
}
@media (max-width: 767px) {
    .AddBooking_logo__3umxJ {
        color: white;
        font-size: 1.3rem;
        line-height: 1.4; 
        font-weight: 700;
        /* padding:32px; */
    }
    .AddBooking_firstColumnheader__2ygdq {
        font-size: 26px;
    }
}

