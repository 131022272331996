/* Common Styling */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
.appbody {
    background-color: white;
}
.colContainer {
    border: 15px;
    padding: 30px;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}
.bottomlink {
}
.containerInside {
    width: 1140px;
    height: 100%;
}
.formaterBlock {
    display: none;
}
/* Styling for header */
.header {
    /* height: 4rem; */
    background-color: #fafafa;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.headerInside {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerRight {
    display: flex;
    align-items: center;
}
.headertext {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
}
.headerLeft {
    display: flex;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
}
.headerLeftTabs {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.headerLeftTabsdata {
    /* text-align: center; */
    line-height: 0.7;
    /* margin-top: 5px; */
}
.extramargin {
    margin-left: 250px !important;
    padding: 5px;
}
.headerLeftTabsheader {
    /* text-align: center; */
    font-size: 0.8rem;
    color: black;
    font-weight: bold;
}

/* Styling for Container 1 */
.container1 {
    /* background-image: url('../../../assets/img/images/5.jpg');
    background-size: cover;
    background-position: center; */

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* background-color: white; */
}
.container1supp {
    /* display: flex; */
    /* justify-content: center; */
    /* min-height: "100%"; */
    /* align-items: center; */
    /* background-color: red; */
    /* overflow:hidden; */
    /* width: 100%; */
    /* background-color: white; */
}
@media (min-width: 768px) {
    .container1supp {
        display: flex;
        min-height: 650px;
    }
}
.imgContainer {
    border: 15px;
    /* padding: 30px; */
    padding-top: 30px;
    /* padding-right: 30px; */
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}
/* .homepageHeroImage{

    height: 470px;
    width: 470px;

    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;

    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
} */
.homepageHeroImage {
    max-height: 500px;
    max-width: 500px;
    overflow: hidden;
    /* object-fit: cover; */
    /* border: 9px solid #292d33; */
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    /* position: absolute; */
    border-radius: 5px;
    /* background-color: white;
}
.homepageHeroImagecont{
    height: 450px;
    width: 450px;
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    position: absolute;
    border-radius: 5px;
    display: flex;
    justify-content: end; */
}
.homepageHeroImageBox1 {
    height: 500px;
    width: 250px;
    background-color: rgb(0, 123, 255, 0.85);
    border-radius: 5px;
    position: relative;
    margin-left: 240px;
}
.homepageHeroImageBox2 {
    height: 180px;
    width: 500px;
    background-color: rgb(224, 187, 228, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    position: absolute;
    /* top: 200px; */
    margin-top: 250px;
}

.homepageHerofont {
    font-size: 3.75rem;
    font-weight: 700;
    color: black;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.1;
}

.homepageHeropara {
    font-size: 1.25rem;
    margin: 29px 0 0;
    color: black;
    word-spacing: 0.2;
    /* background-color: pink; */
    /* border-bottom: 4px solid black; */
    line-height: 1.6;
    font-family: 'Open Sans', sans-serif;
}
.homepageHerobtncontainer {
    align-self: flex-start;
    margin: 22px 0 0;
}
.homepageHerobtn {
    width: 160px;
    margin-right: 10px;
    margin-top: 10px;

    padding: 10px 20px;
    font-size: 0.9375rem;
    font-weight: 500;
    background-color: white;
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.homepageHerobtnmail {
    margin-right: 10px;
    margin-top: 10px;
    width: 160px;
    padding: 10px 20px;
    font-size: 0.9375rem;
    font-weight: 500;
    background-color: white;
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.homepageHerobtnpay {
    display: none;
}
.homepageHerobtn:hover {
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.homepageHerobtnpay:hover {
    border: 1px solid black;
    /* border: 1px solid #007bff; */
}
.homepageHerobtn2 {
    width: 160px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid black;
    padding: 10px 40px;
    font-size: 0.9375rem;
    font-weight: 500;
}
.homepageHerobtn2:hover {
    background-color: white;
    border: 1px solid black;
}

/* Styling for Container 2 */
.container2 {
    background-color: #292d33;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 40px;
}
.container2Inside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.container2Header {
    color: white;
    font-size: 3.43rem;
    margin-top: 60px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    /* width: 753px; */
}
.container2Desc {
    color: white;
    font-size: 1.125rem;
    margin-top: 20px;
    text-align: center;
    width: 659px;
}
.container2Cardscontainer {
    /* display: flex; */
    /* justify-content: space-around; */
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
}
.container2Card {
    background-color: white;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}
.containerteamCard {
    /* background-color: white; */
    /* border-radius: 20px; */
    /* background: rgba(0,0,0,0.5); */
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

/* Styling For Container 3 */
.container3 {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url('../../../assets/img/images/t4.png');
    background-size: cover;
    background-position: center;
    background-color: #f2f2f2;
}
.container3Inside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.container3Header {
    color: black;
    font-size: 3rem;
    margin-top: 85px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    width: 753px;
}
.container3Cardscontainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 60px;
}
.container3Card {
    background-color: white;
    border-radius: 20px;
    /* height: 40px; */
    margin: 10px;
    padding: 30px;
    display: flex;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
}

/* Styling For Container 4 */
.container4 {
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffca01;
}
.container4Header {
    color: black;
    font-size: 2.5rem;
    margin-top: 20x;
    /* text-align: center; */
    line-height: 1.1;
    font-weight: 700;
    /* width: 753px; */
}
.container4mod {
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffca01;
}
.container4modInside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.container4Cardscontainer {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 80px;
}

.containersend {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    /* overflow:hidden;
   background-image: url('../../../assets/img/images/t4.png');
   background-size: cover;
   background-position: center; */
}
/* Styling For Container 5 */
.container5 {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
}

.container5Inside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.container5Header {
    color: black;
    font-size: 3.43rem;
    margin-top: 60px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    width: 753px;
}
.containersendHeader {
    color: black;
    font-size: 2rem;
    margin-top: 40px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    /* width: 753px; */
}

.containersendCardscontainer {
    /* width: 5000; */
    margin-top: 30px;
    margin-bottom: 30px;
}
.container5Cardscontainer {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.container5Image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.contactUsBox {
    width: 900px;
}
/* New Styling addition */
.container14 {
    background-color: #292d33;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 80px;
    padding-top: 80px;
}

.container15 {
    background-color: #212121;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 25px;
}
.bottomBand {
    display: flex;
    justify-content: space-between;
    color: white;
    text-align: center;
    align-items: center;
}
.secondBottomBand {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}
.secondBottomtext {
    font-size: 2.3rem;
    color: white;
    text-align: center;
}
.secondBottomaddhead {
    font-size: 1.4rem;
    color: #d9d4d4;
}
.secondBottomadd {
    font-size: 1rem;
    color: #a6a6a6;
    white-space: pre-line;
    margin-top: 20px;
}
.imageGallary {
    width: 161px;
    height: 161px;
    object-fit: cover;
    margin: 10px;
    cursor: pointer;
}
.container8 {
    background-color: #292d33;
    /* padding-bottom: 40px; */
}

.submitButton {
    border: none;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    background-color: #0173ce;
    border-radius: 5px;
}
.aboutUsimage {
    /* height: "250px",
    width: "250px", */
    height: 280px;
    width: 280px;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .containerInside {
        width: 940px;
        height: 100%;
    }

    .homepageHerofont {
        font-size: 3.125rem;
        font-weight: 700;
        color: black;
    }
    /* .homepageHeroImage{

        max-height: 590px;
        width: auto;


    } */
    .contactUsBox {
        width: 900px;
    }
    .homepageHeroImage {
        max-height: 500px;
        max-width: 500px;
        background-color: white;
    }

    .homepageHeroImageBox1 {
        height: 450px;
        width: 200px;
        background-color: rgb(0, 123, 255, 0.85);
        border-radius: 5px;
        position: relative;
        margin-left: 290px;
    }

    .homepageHeroImageBox2 {
        height: 140px;
        width: 430px;
        background-color: rgb(224, 187, 228, 0.9);
        backdrop-filter: blur(10px);
        border-radius: 5px;
        position: absolute;
        /* top: 200px; */
        margin-top: 250px;
    }
    .container5Image {
        width: 100%;
        height: 321px;
        object-fit: cover;
    }
    .imageGallary {
        width: 161px;
        height: 161px;
        object-fit: cover;
        margin: 10px;
    }
}
@media (min-width: 768px) and (max-width: 990px) {
    .interestedtext {
        font-size: 40px !important;
    }
    .getquotebtn {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        padding-right: 18px !important;
        padding-left: 18px !important;
        font-size: 18px !important;
    }
    .headerLeft {
        display: none;
    }
    .homepageHerobtnpay {
        display: block;
        margin-right: 10px;
        margin-top: 10px;

        padding: 10px 20px;
        font-size: 0.9375rem;
        font-weight: 500;
        background-color: white;
        border: 1px solid black;
        /* border: 1px solid #007bff; */
    }
    .containerInside {
        width: 720px;
        height: 100%;
    }
    .contactUsBox {
        width: 100%;
    }
    /* .homepageHeroImage{

        max-height: 470px;
        width: auto;

    } */
    .homepageHeroImage {
        max-height: 380px;
        max-width: 380px;
        background-color: white;
    }

    .homepageHeroImageBox1 {
        height: 400px;
        width: 150px;
        background-color: rgb(0, 123, 255, 0.85);
        border-radius: 5px;
        position: relative;
        margin-left: 290px;
    }

    .homepageHeroImageBox2 {
        height: 120px;
        width: 380px;
        background-color: rgb(224, 187, 228, 0.9);
        backdrop-filter: blur(10px);
        border-radius: 5px;
        position: absolute;
        /* top: 200px; */
        margin-top: 250px;
    }
    .homepageHerofont {
        font-size: 2.25rem;
        font-weight: 700;
        color: black;
    }
    .homepageHeropara {
        font-size: 1.125rem;
        margin: 29px 0 0;
        color: black;
    }
    .homepageHerobtncontainer {
        align-self: flex-start;
        margin: 19px 0 0;
    }
    .imageGallary {
        width: 161px;
        height: 161px;
        object-fit: cover;
        margin: 10px;
    }
    .extramargin {
        margin-left: 100px !important;
        padding: 5px;
    }
}
@media (min-width: 575px) and (max-width: 767px) {
    .interestedtext {
        font-size: 30px !important;
    }
    .extramargin {
        margin-left: 0px !important;
        padding: 5px;
    }
    .getquotebtn {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-right: 17px !important;
        padding-left: 17px !important;
        font-size: 15px !important;
    }
    .headerLeft {
        display: none;
    }
    .contactUsBox {
        width: 100%;
    }
    .homepageHerobtnpay {
        display: block;
        margin-right: 10px;
        margin-top: 10px;

        padding: 10px 20px;
        font-size: 0.9375rem;
        font-weight: 500;
        background-color: white;
        /* border: 1px solid #007bff; */
        border: 1px solid black;
    }
    .containerInside {
        width: 540px;
        height: 100%;
    }
    .container1 {
        padding-top: 20px;
    }
    /* .homepageHeroImage{

        max-height: 470px;
        width: auto;

    } */
    .homepageHeroImage {
        max-height: 380px;
        max-width: 3880px;
        background-color: white;
    }

    .homepageHeroImageBox1 {
        height: 400px;
        width: 150px;
        background-color: rgb(0, 123, 255, 0.85);
        border-radius: 5px;
        position: relative;
        margin-left: 290px;
        margin-bottom: 15px;
    }

    .homepageHeroImageBox2 {
        height: 120px;
        width: 380px;
        background-color: rgb(224, 187, 228, 0.9);
        backdrop-filter: blur(10px);
        border-radius: 5px;
        position: absolute;
        /* top: 200px; */
        margin-top: 250px;
    }
    .homepageHerofont {
        font-size: 2.25rem;
        font-weight: 700;
        color: black;
    }
    .homepageHeropara {
        font-size: 1.125rem;
        margin: 29px 0 0;
        color: black;
    }
    .homepageHerobtncontainer {
        align-self: flex-start;
        margin: 19px 0 0;
    }
    .container2Header {
        width: 520px;
    }
    .container2Desc {
        width: 520px;
    }
    .container3Header {
        width: 520px;
    }
    .container5Header {
        width: 520px;
    }
    .imageGallary {
        width: 161px;
        height: 161px;
        object-fit: cover;
        margin: 10px;
    }
    .secondBottomBand {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .secondBottomaddhead {
        font-size: 1.2rem;
        color: #d9d4d4;
        margin-top: 30px;
    }
}
@media (max-width: 574px) {
    .bottomlink {
        margin-right: 0px !important;
        margin-top: 10px;
    }
    .extratop {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .interestedtext {
        font-size: 30px !important;
    }
    .extramargin {
        margin-left: 0px !important;
        padding: 5px;
    }
    .getquotebtn {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
        font-size: 15px !important;
    }
    .headerLeft {
        display: none;
    }
    .contactUsBox {
        width: 100%;
    }
    .homepageHerobtnpay {
        display: block;
        margin-right: 10px;
        margin-top: 10px;

        padding: 10px 20px;
        font-size: 0.9375rem;
        font-weight: 500;
        background-color: white;
        border: 1px solid black;
        /* border: 1px solid #007bff; */
    }
    .aboutUsimage {
        /* height: "250px",
        width: "250px", */
        height: 200px;
        width: 200px;
    }
    .containerInside {
        max-width: 340px;
        width: 100%;
        height: 100%;
    }
    .imgContainer {
        padding: 5px 0px;
    }
    .colContainer {
        padding: 10px;
        padding-bottom: 50px !important;
        margin: 10px !important;
    }
    .container1 {
        padding-top: 20px;
    }
    /* .homepageHeroImage{

        max-height: 470px;
        width: auto;

    } */
    .homepageHeroImage {
        max-height: 330px;
        max-width: 330px;
        background-color: white;
        position: static;
    }

    .homepageHeroImageBox1 {
        display: none;
    }

    .homepageHeroImageBox2 {
        display: none;
    }
    .homepageHerofont {
        font-size: 2.25rem;
        font-weight: 700;
        color: black;
    }
    .homepageHeropara {
        font-size: 1.125rem;
        margin: 29px 0 0;
        color: black;
    }
    .homepageHerobtncontainer {
        align-self: flex-start;
        margin: 19px 0 0;
    }
    .container2Header {
        font-size: 1.82rem;
        max-width: 340px;
        width: 100%;
    }
    .container2Desc {
        max-width: 340px;
        width: 100%;
    }
    .container3Header {
        font-size: 1.82rem;
        max-width: 340px;
        width: 100%;
    }

    .container5Header {
        font-size: 1.82rem;
        max-width: 340px;
        width: 100%;
    }
    .container4Header {
        font-size: 1.82rem;
        width: 100%;
    }
    .imageGallary {
        max-width: 181px;
        width: 100%;
        height: 181px;
        object-fit: cover;
        margin: 10px;
    }
    .bottomBand {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
        text-align: center;
    }
    .secondBottomBand {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .secondBottomaddhead {
        font-size: 1.1rem;
        color: #d9d4d4;
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    /* .colContainer{
        text-align: center !important;
    } */
    .homepageHerofont {
        text-align: center !important;
    }
    .homepageHeropara {
        text-align: center !important;
    }
    .homepageHerobtncontainer {
        text-align: center !important;
        align-self: center !important;
    }
    .homepageHerobtnpay {
        width: 97% !important;
    }
    .homepageHerobtn2 {
        width: 97% !important;
    }
    .homepageHerobtnmail {
        width: 97% !important;
    }
    .homepageHerobtn {
        width: 97% !important;
    }
    .formaterBlock {
        display: block;
    }
}
